const Testimonials= [
 { id:1,
  Video:"/Testimony1.mp4"
 },

 { id:2,
  Video:"/Testimony2.mp4"
 },



 { id:4,
  Video:"/tempfile2.mp4"
 }

 




]


export default Testimonials ;