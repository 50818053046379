const Profiles= [
 { id:1,
  Icon:"/prof2.png",
  headtext:"Ugwuanyi Nicholason",
  bodytext:"Founder"
},
{ id:2,
 Icon:"/prof4.png",
 headtext:"Dr. Amidu James",
 bodytext:" Secretary"

},


{ id:3,
 Icon:"/prof1.png",
 headtext:"Barr. Mrs Ojochide Suleiman",
 bodytext:" Legal Counsel"

},

{ id:4,
 Icon:"/prof8.png",
 headtext:"Mr.   Muhamed Kabiru Usman",
 bodytext:" Program manager"

},


{ id:5,
 Icon:"/profile7.jpg",
 headtext:"Dr. Charity Ugwu",
 bodytext:" Medical Team Lead"

},

{ id:6,
 Icon:"/prof5.png",
 headtext:"Hon Sam Adejoh Okedi",
 bodytext:" Trustee"

},


{ id:7,
 Icon:"/prof3.png",
 headtext:"Pharm Peter Yakubu Iduh",
 bodytext:" Trustee"

},



{ id:8,
 Icon:"/prof6.png",
 headtext:"Mr. Abdulquadir Hussaini Mohammed",
 bodytext:" Trustee"

},


{ id:9,
  Icon:"/prof9.png",
  headtext:"Dr. Esther Ezea",
  bodytext:"Program Developer"
 
 },

 { id:10,
  Icon:"/gid.jpg",
  headtext:"Mr. Ameh Gideon",
  bodytext:"Communication Manager"
 
 },




]


export default Profiles ;