const Doings = [
 { id:1,
  topN:4,
  
  btext:" Years of helping Children"
},
{ id:2,
 topN:80,
  
  btext:" Active Volunteer"

},

{ id:3,
 topN:'33845', 

  btext:" Girls Assisted"

},
{ id:3,
 topN:6,

  btext:"   Active Partners"

}


]
export default  Doings;