const Values= [
 { id:1,
  Icon:"/eye.png",
  headtext:"Our Vision",
  bodytext:"Through our campaign project “GIVE OUT A PADSAVE A GIRL CHILD” we passionately strive to eradicate period poverty, menstrual stigma, menstrual shame and menstrual taboos for the vulnerable girls, women, female inmates and female genders with disabilities and to be an organization that continually respond to the changing social realities through the development and application of menstrual hygiene management towards development of sustainable society that promote and protect the integrity of the average girl child regards managing her menstrual cycles"
},
{ id:2,
 Icon:"/Mission.jpg",
 headtext:"Mission",
 bodytext:" To work towards the promotion of the aims and objective of the United Nations and its system for development of society dominantly rural communities, prisons, disability centers, IDP camps and schools and in pursuance of its vision and guiding principle. The organization organize  programs to facilitate the accessibility of sanitary pads and menstrual products for the average girl child, women, female inmates and genders with disabilities through creating accessible pad banks."

},

{ id:3,
 Icon:"/gal (11).JPG",
 headtext:"History",
 bodytext:"Nicholasmotivates initiative was established in 2019 and registered as a non profit in 2021 and since then the organization has been expanding continuously in terms of social development service. It has worked for the promotion of sustainable, equitable and participatory development and social welfare program for social work, first hand engagement with target audiences, health service and other menstrual hygiene management practices through social research and dissemination of socially relevant knowledge, social intervention through training and field action, contribution to social and welfare policy and program at local, state, and national level. Over the years the organization has among others NGOs thrusts made a significant contribution to planning, action strategies and menstrual hygiene management of the girl child in several areas ranging from sustainable rural and urban development to menstrual hygiene management, gender equality and inclusivity. in all case, the focus has been on the disadvantaged and marginalized section of societies such as girls, women, female inmates and female genders with disabilities. Nicholasmotivates initiative has earned recognition as an institution (Organization) of repute from local and state government of nigeria and various local and national NGOs."

},
{ id:4,
 Icon:"/nmi86.JPG",
 headtext:"Purpose",
 bodytext:" Promoting the aims and purpose of the world health organization, united nations and its system among the various societies of the different states in Nigeria. As an organization, we are actively working for the social development of rural and tribal communities, schools, prisons, IDP camps and shelters regards ending period poverty through availability of free sanitary pads and menstrual products. We are working in education, health, girl child, women, female inmate and female genders with disabilities development programs."

},
{ id:5,
 Icon:"/gal (19).JPG",
 headtext:"Aims and Objectives",
 bodytext:" The improvement of sanitation facilities, routine distribution of sanitary pads in public facilities like schools, prisons, rural communities IDP camps and shelters. An improved Mother-daughter Relationship as well as sensitization of the male gender on menstrual inequality, menstrual shame and menstrual taboos with the theme; “BRINGING BOYS AND MEN INTO MENSTRUATION, THE ROLES OF THE MALE GENDER IN MENSTRUAL HYGIENE MANAGEMENT. "

},


]


export default Values ;