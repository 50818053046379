const CauseItems= [
 { id:1,
  Icon:"/gal (11).JPG",
  headtext:"Values and Principles",
  bodytext:"Nicholasmotivates Initiative encourages the following values to be practiced both at individual and organizational level; Honesty, Transparency and Accountability, Mutual respect, Creativity, Gender sensitiveness, Cost efficiency and Participation Solidarity. Inclusivity and diversity, empathy and girl child power."
},
{ id:2,
 Icon:"/gal (24).JPG",
 headtext:"Guiding Principles",
 bodytext:" Utilization of local wisdom and resources. Encouragement of innovation and creativity. Prioritizing quality. Enhancing coordination and networking. Accepting challenges "

},

{ id:3,
 Icon:"/nmi7.jpg",
 headtext:"Organization Believe",
 bodytext:" We believe in the potential power of the vulnerable girl child, women, female inmates, female genders with disabilities as well as rural people to participate actively in good menstrual hygiene management in equal opportunity for girls, women, female inmates and female genders with disabilities in society irrespective of class, age, ethnic, culture or religion. All human rights for all, In freedom of menstrual values and integrity in social harmony free from communal bias, freedom for work, education and development."

},

{ id:4,
 Icon:"/nmi2.png",
 headtext:"Our values and principle",
 bodytext:" For the past years we have been working in field of: Advocacy for the promotion of the values and integrity of the girl child, women, female inmates and female genders with disabilities regards their menstrual hygiene management through sensitization programs, community outreaches, prison outreach sensitization programs, disability centers and IDP camps outreach programs where pad banks are being planted which creates accessibility of sanitary pads and menstrual products for our target audience. We have advocated gender equality and sexual harassment, sustainable development, rural development, promotion and protection of the menstrual integrity of the girl child, human rights of the girl child, women, female inmates and female genders with disabilities. We have made significant contributions in menstrual hygiene management education, reproductive health, girl child and women development as well as research program in menstrual hygiene management. We have engaged the male gender with a vigorous campaign with the theme; “THE ROLES OF BOYS AND MEN IN MENSTRUAL HYGIENE MANAGEMENT; BRING THE MALE GENDER INTO MENSTRUATION. Through the campaign we have being able to foster an awareness drive targeted at mitigating menstrual shame, taboos and stigmas."

},

{ id:5,
 Icon:"/nmi30.jpg",
 headtext:"Issues of Interest",
 bodytext:"Our core interest are fixated on the development of the menstrual hygiene management of the girl child, women, female inmates and female genders with disabilities spread across rural communities, schools, prisons, IDP camps and shelters and in extension gender based violence and access to SRHR information."

},


{ id:6,
 Icon:"/nmi30.jpg",
 headtext:"Our focus areas for rural development",
 bodytext:"Our rural development key areas are Education, Health and Family welfare, Sustainable  menstrual hygiene products, Gender-based Violence Eradication, Access to SRHR Information and Social causes."

},



{ id:7,
 Icon:"/gal (16).JPG",
 headtext:"Education",
 bodytext:"Girl child education, Non-formal education, Tribal education, Gender-based Violence Awareness (GBVA) and Access to Sexual and Reproductive Health right (SRHR) Information ."

},


{ id:8,
 Icon:"/gal (18).JPG",
 headtext:"Health and family welfare ",

 bodytext:"We are concerned about the menstrual hygiene management of our target audience through medical camp health awareness programs, good sanitation, safe drinking water and girl child reproductive health awareness building."

},


]


export default CauseItems ;