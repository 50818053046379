



const Videoclips= [
 
 { id:1,
  Video:"/naso.mp4"
 },

 
 { id:2,
  Video:"/idp.mp4"
 },
 

 

 




]

export default Videoclips